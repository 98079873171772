<template>
    <div>
        <!-- TODO: Please me/you, make these two separate components (Payment && AutoPay) and clean up -->
        <page-header
            :title="pageTitle"
            :show-back="!isModal"
            :show-close="isModal"
            @onClose="onClose"
        />
        <loading-indicator
            v-if="loading"
            :title="$t(isAutoPayTabActive ? 'pages.MakePayment.ProcessingAutoPay' : 'pages.MakePayment.ProcessingPayment')"
        />
        <!-- One Time Payment Complete Confirmation -->
        <div v-show="currentScreen === Screens.oneTimePaymentComplete && !loading">
            <div class="container text-center">
                <img
                    src="@/assets/images/pages/card/PaymentSuccessful.svg"
                    :alt="$t('pages.MakePayment.madePaymentConfirmation.title')"
                >

                <h5
                    class="text-center fw-light my-1"
                    v-html="$t('pages.MakePayment.madePaymentConfirmation.title')"
                />

                <div class="text-muted text-center mb-3">
                    <span v-html="$t('pages.MakePayment.madePaymentConfirmation.subTitle', { paidAmount: toFormattedUSD(amountPaid), source: this.paymentSourceNickname })" />
                </div>

                <base-button
                    class="mb-2"
                    button-classes="btn btn-secondary"
                    @click="returnToRoot"
                >
                    {{ $t('pages.MakePayment.madePaymentConfirmation.doneCta') }}
                </base-button>
            </div>
        </div>
        <!-- Auto Pay Setup Complete Confirmation -->
        <div
            v-show="currentScreen === Screens.autoPayEnabledConfirmation && !loading"
            class="container text-center"
            data-testid="autopay-confirmation-screen"
        >
            <img
                src="@/assets/images/pages/card/PaymentSuccessful.svg"
                :alt="$t('pages.MakePayment.autoPayEnabledConfirmation.title')"
            >

            <h5
                class="text-center fw-light my-1"
                v-html="$t('pages.MakePayment.autoPayEnabledConfirmation.title')"
            />

            <div class="text-muted text-center mb-3">
                <span
                    v-show="selectedPaymentOption === PaymentOptionTypes.STATEMENT_BALANCE"
                    v-html="$t('pages.MakePayment.autoPayEnabledConfirmation.subTitleStatementBalance', { source: this.paymentSourceNickname })"
                />
                <span
                    v-show="selectedPaymentOption === PaymentOptionTypes.MINIMUM_DUE"
                    v-html="$t('pages.MakePayment.autoPayEnabledConfirmation.subTitleMinimumDue', { source: this.paymentSourceNickname })"
                />
                <span
                    v-show="selectedPaymentOption === PaymentOptionTypes.CUSTOM_AMOUNT"
                    v-html="$t('pages.MakePayment.autoPayEnabledConfirmation.subTitleFixedAmount', { source: this.paymentSourceNickname, amount: this.toFormattedUSD(this.amount) })"
                />
            </div>

            <base-button
                class="mb-2"
                button-classes="btn btn-secondary"
                @click="returnToRoot"
                data-testid="autopay-button-done"
            >
                {{ $t('pages.MakePayment.autoPayEnabledConfirmation.doneCta') }}
            </base-button>
        </div>
        <!-- when autoPay tab is selected and not eligible to edit auto pay -->
        <div
            v-show="isAutoPayTabActive && !canChangeAutoPaySetting && !loading"
            class="container mt-2"
        >
            <p
                v-if="autoPaySetting.isEnabled"
                class="text-muted text-center mt-2"
            >
                {{ $t(`pages.MakePayment.SecondaryAutoPayEdit`) }}
            </p>
            <p
                v-else
                class="text-muted text-center mt-2"
            >
                {{ $t(`pages.MakePayment.SecondaryAutoPayAdd`) }}
            </p>
        </div>
        <!-- makePaymentOrAutoPay includes (One Time Payment, AutoPay tab), confirmOneTimePayment -->
        <form-container
            v-show="!loading && ![Screens.oneTimePaymentComplete, Screens.autoPayEnabledConfirmation].includes(currentScreen)"
            @onSubmit="validateAndSubmit"
            id="paymentOptionsForm"
            ref="paymentOptionsForm"
            class="container"
        >
            <!-- Confirm One Time Payment -->
            <div v-if="currentScreen === Screens.confirmOneTimePayment">
                A payment of <span class="fw-bold">{{ toFormattedUSD(this.amount) }}</span> will be withdrawn from your account ending in {{ paymentSourceLast4 }}.
                <div class="card border-0 p-2 mb-3 mt-3 bg-light">
                    <div class="d-flex justify-content-between">
                        <div class="text-start">
                            <p class="mb-2">
                                From
                            </p>
                        </div>

                        <div class="text-end">
                            <p class="mb-2 fw-bold">
                                {{ paymentSourceNickname }}
                            </p>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div class="text-start">
                            <p class="mb-0">
                                To
                            </p>
                        </div>

                        <div class="text-end">
                            <p class="mb-2 fw-bold">
                                Aven Card ({{ last4CardNumber }})
                            </p>
                        </div>
                    </div>

                    <div class="d-flex justify-content-between">
                        <div class="text-start">
                            <p class="mb-0">
                                Amount
                            </p>
                        </div>

                        <div class="text-end">
                            <p class="fw-bold mb-2">
                                {{ toFormattedUSD(amount) }}
                            </p>
                        </div>
                    </div>

                    <div class="d-flex justify-content-between">
                        <div class="text-start">
                            <p class="mb-0">
                                Date
                            </p>
                        </div>

                        <div class="text-end">
                            <p class="fw-bold mb-0">
                                Today, {{ this.formattedPaymentDateToday(new Date()) }}
                            </p>
                        </div>
                    </div>
                </div>
                <p
                    class="small text-muted mb-2"
                    v-html="
                        $t('pages.MakePayment.terms2', {
                            amount: toFormattedUSD(this.amount),
                            date: new Date().toLocaleDateString(),
                            source: this.paymentSourceNickname,
                        })
                    "
                />
                <div v-if="isLargePayment(this.amount)">
                    <form-field-currency
                        v-model="confirmPaymentAmount"
                        container-classes="mb-2 form-row"
                        name="confirmPaymentAmount"
                        :validation="`required|confirmPaymentAmount:${this.amount}`"
                        :placeholder="$t('pages.MakePayment.ConfirmAmount', { amount: this.amount.toFixed(2) })"
                    />
                </div>
                <base-button
                    :submitting="submitting"
                    :disabled="isConfirmPaymentDisabled"
                >
                    Confirm Payment
                </base-button>
            </div>
            <div v-else>
                <!-- Switch Tab Slider -->
                <switch-slider
                    class="mb-2"
                    id="toggleAutopaySlider"
                    v-model="isAutoPayTabActive"
                    v-show="!configureAutoPay"
                    event-name="payment_autopay"
                    :event-props="{ isAutoPayTabActive }"
                    :left-label="$t('pages.MakePayment.oneTime')"
                    :right-label="$t('pages.MakePayment.autoPay')"
                    @click="switchTabs"
                    data-testid="toggle-payment-slider"
                />
                <transition
                    class="container"
                    name="slide-fade"
                    mode="out-in"
                >
                    <!-- empty transition while loading -->
                    <div
                        v-if="loading"
                        key="loading"
                    >
&nbsp;
                    </div>
                    <!-- when autoPay tab is selected and auto pay is enabled, show current auto pay setting,  -->
                    <div
                        v-else-if="isAutoPayTabActive && autoPaySetting.isEnabled && !isEditingAutoPaySetting && !showDisableAutoPaySetting"
                        class="mt-4"
                    >
                        <list-row
                            title="AutoPay"
                            value="On"
                            :on="true"
                            value-class="text-muted"
                            contained
                        />
                        <list-row
                            title="Amount"
                            :value="autopayCategoryAmount"
                            value-class="text-muted"
                            contained
                        />
                        <list-row
                            title="Pay From"
                            :value="autopayFundingNickname"
                            value-class="text-muted"
                            contained
                        />
                        <list-row
                            title="Next Payment"
                            :value="nextAutoPayAmountAndDate"
                            value-class="text-muted"
                            contained
                        />
                        <base-button
                            class="my-3"
                            type="button"
                            button-classes="btn btn-primary"
                            :submitting="submitting"
                            @click="isEditingAutoPaySetting = true"
                        >
                            {{ $t(`pages.MakePayment.UpdateAutoPay`) }}
                        </base-button>
                        <base-button
                            class="my-3"
                            type="button"
                            button-classes="btn btn-tertiary"
                            :submitting="submitting"
                            @click="showDisableAutoPaySetting = true"
                            data-testid="button-turn-off-autopay"
                        >
                            {{ $t(`pages.MakePayment.TurnOffAutoPay`) }}
                        </base-button>
                    </div>
                    <div v-else-if="showDisableAutoPaySetting">
                        <div class="text-black">
                            {{ $t(`pages.MakePayment.autoPayDisabledConfirmation.subTitle1`) }}
                        </div>
                        <div class="text-black mt-3">
                            {{ $t(`pages.MakePayment.autoPayDisabledConfirmation.subTitle2`) }}
                        </div>
                        <base-button
                            class="my-3"
                            type="button"
                            button-classes="btn btn-primary"
                            :submitting="submitting"
                            @click="onClickAutoPayOptOut"
                            data-testid="button-confirm-turn-off-autopay"
                        >
                            {{ $t(`pages.MakePayment.TurnOffAutoPay`) }}
                        </base-button>
                        <base-button
                            class="my-3"
                            type="button"
                            button-classes="btn btn-secondary"
                            :submitting="submitting"
                            @click="showDisableAutoPaySetting = false"
                        >
                            {{ $t(`pages.MakePayment.autoPayDisabledConfirmation.keepOnBtnCta`) }}
                        </base-button>
                    </div>
                    <!-- form below is used for one time payment and editing/setup auto pay -->
                    <div v-else>
                        <section-header class="mt-sm-2">
                            {{ $t('pages.MakePayment.Amount') }}
                        </section-header>
                        <base-radio
                            :id="PaymentOptionTypes.MINIMUM_DUE"
                            :label="$t('pages.MakePayment.MinimumDueLabel')"
                            :value-label="isAutoPayTabActive ? '' : `${toFormattedUSD(minimumDue)}`"
                            v-model="selectedPaymentOption"
                            container-classes="custom-control-radio"
                            :disabled="minimumDue <= 0 && !isAutoPayTabActive"
                            data-testid="radio-button-minimum-due"
                        />
                        <base-radio
                            :id="PaymentOptionTypes.STATEMENT_BALANCE"
                            :label="$t('pages.MakePayment.StatementBalanceLabel')"
                            :value-label="isAutoPayTabActive ? '' : `${toFormattedUSD(statementRemainingBalance)}`"
                            v-model="selectedPaymentOption"
                            container-classes="custom-control-radio"
                            :disabled="statementRemainingBalance <= 0 && !isAutoPayTabActive"
                            data-testid="radio-button-statement-balance"
                        />
                        <base-radio
                            v-if="!isAutoPayTabActive"
                            :id="PaymentOptionTypes.CURRENT_BALANCE"
                            :label="$t('pages.MakePayment.CurrentBalanceLabel')"
                            :value-label="isAutoPayTabActive ? '' : `${toFormattedUSD(currentBalance)}`"
                            v-model="selectedPaymentOption"
                            container-classes="custom-control-radio"
                            :disabled="currentBalance <= 0"
                            data-testid="radio-button-current-balance"
                        />
                        <base-radio
                            :id="PaymentOptionTypes.CUSTOM_AMOUNT"
                            :label="$t(isAutoPayTabActive ? 'pages.MakePayment.FixedAmountLabel' : 'pages.MakePayment.OtherAmountLabel')"
                            v-model="selectedPaymentOption"
                            container-classes="custom-control-radio"
                            data-testid="radio-button-custom-amount"
                        />
                        <div v-if="selectedPaymentOption === PaymentOptionTypes.CUSTOM_AMOUNT">
                            <form-field-currency
                                v-model="customPaymentAmount"
                                container-classes="mb-2 form-row"
                                name="otherAmountField"
                                :validation="`currency:1|maxAllowedByACI`"
                                placeholder="$100"
                                data-testid="make-payment-field-custom-amount"
                            />
                        </div>
                        <section-header>{{ $t('pages.MakePayment.PayFrom') }}</section-header>
                        <div v-if="paymentSources.length > 0">
                            <select
                                id="paymentSource"
                                name="paymentSource"
                                v-model="selectedSavedPaymentSource"
                                class="form-control"
                                :class="{ 'mb-2': haveSelectedPaymentSource }"
                                data-testid="payment-sources"
                            >
                                <option
                                    v-for="(paymentSource, index) in paymentSources"
                                    :key="index"
                                    :value="index"
                                >
                                    <!-- each paymentSource is an array of values, display string is at index 0 of a paymentSource -->
                                    {{ paymentSource.nickName }}
                                </option>
                                <option :value="PaymentSources.ADD_PAYMENT">
                                    {{ $t('pages.MakePayment.AddNewAccount') }}
                                </option>
                            </select>
                        </div>
                        <div v-if="selectedSavedPaymentSource === PaymentSources.ADD_PAYMENT || paymentSources.length === 0">
                            <div
                                class="mb-2"
                                :class="{ 'mt-2': !haveSelectedPaymentSource }"
                            >
                                <div class="input">
                                    <select
                                        name="bankAccountType"
                                        v-model="bankAccountType"
                                        class="form-control"
                                    >
                                        <option
                                            v-for="type in BankAccountTypes"
                                            :key="type.value"
                                            :value="type.value"
                                        >
                                            {{ type.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <img
                                src="../assets/check-bottom-clipped.jpg"
                                alt="Contact your bank for your routing and account number"
                                class="w-100"
                            >
                            <form-field
                                class="mt-2"
                                v-model="routingNumber"
                                input-type="tel"
                                inputmode="decimal"
                                ref="routing"
                                name="routing"
                                :validation="`required|min:${bankRoutingNumberLength}|max:${bankRoutingNumberLength}`"
                                :max-length="9"
                                validation-mode="aggressive"
                                :placeholder="$t(`pages.AddBankAccount.RoutingNumber`)"
                                data-testid="form-field-routing-number"
                            />
                            <ValidationObserver>
                                <form-field
                                    v-model="bankAccountNumber"
                                    input-type="tel"
                                    inputmode="decimal"
                                    name="accountnumber"
                                    ref="accountnumber"
                                    :validation="`required|min:${bankAccountNumberMinLength}|max:${bankAccountNumberMaxLength}|numeric`"
                                    :max-length="17"
                                    validation-mode="aggressive"
                                    :placeholder="$t(`pages.AddBankAccount.AccountHolderNumber`)"
                                    data-testid="form-field-account-number"
                                />
                                <form-field
                                    v-model="confirmBankAccountNumber"
                                    input-type="tel"
                                    inputmode="decimal"
                                    name="confirmAccountNumber"
                                    ref="confirmAccountNumber"
                                    :validation="`required|min:${bankAccountNumberMinLength}|max:${bankAccountNumberMaxLength}|numeric|confirmAccountNumber:@accountnumber`"
                                    :max-length="17"
                                    validation-mode="aggressive"
                                    :placeholder="$t(`pages.AddBankAccount.ConfirmAccountHolderNumber`)"
                                    data-testid="form-field-confirm-account-number"
                                />
                            </ValidationObserver>
                            <!-- TODO: implement ability to make payment w/ just acct + routing num (instead of having to create payment source first)-->
                            <!-- <div>
                        <input
                            type="checkbox"
                            name="savePaymentOption"
                            id="savePaymentOption"
                            value="savePaymentOption"
                            v-model="savePaymentOption"
                        >
                        <label for="savePaymentOption"> Save for easier future payments </label>
                    </div>-->
                        </div>
                        <div v-show="isAutoPayTabActive">
                            <p class="small text-muted">
                                {{ $t('pages.AutoPayAmount.legalText1') }}
                            </p>
                            <p
                                class="small text-muted"
                                v-html="$t('pages.AutoPayAmount.legalText2Html')"
                            />
                        </div>
                        <div class="pb-3">
                            <!--this button can be "Turn On AutoPay or "Pay Now"-->
                            <!--amount check is only relevant for "Pay Now" and not for setting up "AutoPay"-->
                            <base-button
                                :submitting="submitting"
                                :disabled="isTurnOnAutoPayOrPayNowButtonDisabled"
                                data-testid="make-payment-button"
                            >
                                {{
                                    $t(isAutoPayTabActive ? 'pages.MakePayment.TurnOnAutoPay' : 'pages.MakePayment.PayNow', {
                                        amount: this.amount > 0 ? toFormattedUSD(this.amount) : '',
                                    })
                                }}
                            </base-button>
                        </div>
                    </div>
                </transition>
            </div>
        </form-container>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapState } from 'vuex'
    import startCase from 'lodash/startCase'
    import { i18n } from '@/utils/i18n'
    import format from '@/mixins/format'
    import FormContainer from '@/components/base/FormContainer'
    import BaseRadio from '@/components/base/BaseRadio'
    import FormField from '@/components/base/FormField'
    import SectionHeader from '../components/SectionHeader'
    import FormFieldCurrency from '../components/base/FormFieldCurrency'
    import PageHeader from '@/components/PageHeader'
    import BaseButton from '@/components/base/BaseButton'
    import {
        BANK_ROUTING_NUMBER_LENGTH,
        BankAccountTypes,
        BANK_ACCOUNT_NUMBER_MAX_LENGTH,
        MAX_AMOUNT_ALLOWED_BY_ACI_FOR_CASH_OUT_IN_DOLLARS,
        MIN_NEED_TO_CONFIRM_PAYMENT_AMOUNT,
        BANK_ACCOUNT_NUMBER_MIN_LENGTH,
        NYC_TIMEZONE,
    } from '@/data/constants'
    import { logger } from '@/utils/logger'
    import { aciMakePayment } from '@/services/api'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import LoadingIndicator from '@/components/LoadingIndicator'
    import ListRow from '@/components/ListRow'
    import SwitchSlider from '@/components/SwitchSlider'
    import generic from '@/utils/generic'
    import { navigation } from '@/mixins/navigation'
    import { DISPLAY_DATE_FORMAT } from '../data/constants'
    import { ValidationObserver } from 'vee-validate'

    const PaymentOptionTypes = {
        MINIMUM_DUE: 'MINIMUM_DUE',
        STATEMENT_BALANCE: 'STATEMENT_BALANCE',
        CURRENT_BALANCE: 'CURRENT_BALANCE',
        CUSTOM_AMOUNT: 'CUSTOM_AMOUNT',
    }

    const PaymentSources = {
        ADD_PAYMENT: -2,
    }

    const Screens = {
        /* displays a div that is used for both make payment and configure auto pay.
            isAutoPayActiveTab determines if div is configured for
                1. one time payment
                2. auto pay

         */
        makePaymentOrAutoPay: 0,
        confirmOneTimePayment: 1,
        oneTimePaymentComplete: 2,
        autoPayEnabledConfirmation: 3,
    }

    export default {
        name: 'MakePayment',
        components: {
            SwitchSlider,
            ListRow,
            LoadingIndicator,
            PageHeader,
            BaseButton,
            BaseRadio,
            SectionHeader,
            FormField,
            FormFieldCurrency,
            FormContainer,
            ValidationObserver,
        },
        props: {
            configureAutoPay: {
                type: Boolean,
                default: false,
            },
            isModal: {
                type: Boolean,
                default: false,
            },
        },
        mixins: [format, navigation], //returnToRoot comes from navigation
        data: function () {
            return {
                Screens,
                PaymentSources,
                PaymentOptionTypes,
                loading: true,
                showConfirmation: false,
                isAutoPayTabActive: false,
                isEditingAutoPaySetting: false,
                showDisableAutoPaySetting: false,
                selectedPaymentOption: PaymentOptionTypes.MINIMUM_DUE,
                BankAccountTypes: BankAccountTypes,
                bankAccountType: BankAccountTypes[0].value,
                bankAccountName: null,
                savePaymentOption: true,
                routingNumber: '',
                bankAccountNumber: '',
                confirmBankAccountNumber: '',
                customPaymentAmount: '',
                confirmPaymentAmount: '',
                paymentDate: null,
                submitting: false,
                amountPaid: 0,

                currentScreen: Screens.makePaymentOrAutoPay,
                selectedSavedPaymentSource: PaymentSources.ADD_PAYMENT,
            }
        },
        mounted: async function () {
            this.loading = true
            this.isAutoPayTabActive = this.configureAutoPay
            await this.getPaymentSourcesAndAutoPayDetails()
            this.switchTabs()
            this.loading = false
        },
        computed: {
            ...mapState(['paymentSources', 'error']),
            ...mapGetters([
                'customerInfo',
                'fullName',
                'currentBalance',
                'minimumDue',
                'statementRemainingBalance',
                'isPrimaryCard',
                'last4CardNumber',
                'isCoApplicantCard',
                'autoPaySetting',
                'nextStatementDate',
                'nextAutoPayAmount',
                'paymentDueDate',
            ]),
            pageTitle: function () {
                if (this.configureAutoPay) {
                    if (this.showDisableAutoPaySetting) {
                        return i18n.t('pages.MakePayment.autoPayDisabledConfirmation.title')
                    } else if (this.autoPaySetting.isEnabled) {
                        return i18n.t('pages.AutoPay.UpdateAutoPay')
                    }
                    return i18n.t('pages.AutoPay.SetupAutoPay')
                } else {
                    return i18n.t('pages.MakePayment.MakePayment')
                }
            },
            bankAccountNumberMinLength: () => BANK_ACCOUNT_NUMBER_MIN_LENGTH,
            bankAccountNumberMaxLength: () => BANK_ACCOUNT_NUMBER_MAX_LENGTH,
            bankRoutingNumberLength: () => BANK_ROUTING_NUMBER_LENGTH,
            amount: function () {
                const amountEntered = parseFloat(this.customPaymentAmount)
                switch (this.selectedPaymentOption) {
                    case PaymentOptionTypes.CURRENT_BALANCE:
                        return this.currentBalance
                    case PaymentOptionTypes.STATEMENT_BALANCE:
                        return this.statementRemainingBalance
                    case PaymentOptionTypes.MINIMUM_DUE:
                        return this.minimumDue
                    case PaymentOptionTypes.CUSTOM_AMOUNT:
                        return isNaN(amountEntered) ? 0 : amountEntered
                    default:
                        return 0
                }
            },
            autoPayCustomAmount: function () {
                // auto pay not enabled
                if (!this.autoPaySetting.isEnabled) {
                    return null
                }
                // auto pay type is not CUSTOM_AMOUNT
                if (this.autoPaySetting.autoPayDetails.autoPayCategory !== `CUSTOM_AMOUNT`) {
                    return null
                } else {
                    return `${this.autoPaySetting.autoPayDetails.customAmount}`
                }
            },
            paymentSourceLast4: function () {
                if (this.selectedSavedPaymentSource === PaymentSources.ADD_PAYMENT) {
                    return this.bankAccountNumber.substring(this.bankAccountNumber.length - 4)
                }
                const paymentSource = this.paymentSources[this.selectedSavedPaymentSource]
                return paymentSource.maskedAccountNumber.substring(paymentSource.maskedAccountNumber.length - 4)
            },
            paymentSourceNickname: function () {
                if (this.selectedSavedPaymentSource === PaymentSources.ADD_PAYMENT) {
                    return this.accountNickName(this.bankAccountType, this.bankAccountNumber)
                }

                return this.paymentSources[this.selectedSavedPaymentSource].nickName
            },
            haveSelectedPaymentSource: function () {
                return this.selectedSavedPaymentSource !== PaymentSources.ADD_PAYMENT
            },
            canChangeAutoPaySetting: function () {
                return this.isPrimaryCard || this.isCoApplicantCard
            },
            autopayCategoryAmount() {
                if (!this.autoPaySetting.isEnabled) {
                    return ''
                }
                const autoPayDetails = this.autoPaySetting.autoPayDetails
                const autoPayCategory = startCase(autoPayDetails.autoPayCategory.toLowerCase())
                if (autoPayDetails.autoPayCategory !== PaymentOptionTypes.CUSTOM_AMOUNT) {
                    return autoPayCategory
                }
                return `${autoPayCategory} (${this.toFormattedUSD(autoPayDetails.customAmount)})`
            },
            autopayFundingNickname: function () {
                if (!this.autoPaySetting.isEnabled) {
                    return ''
                }
                const autoPayAccount = this.paymentSources.find((source) => source.token === this.autoPaySetting.autoPayDetails.aciFundingTokenId)
                return autoPayAccount.nickName
            },
            // button's disabled field -> true will disable it, false will enable it
            //
            isTurnOnAutoPayOrPayNowButtonDisabled: function () {
                if (this.selectedPaymentOption === PaymentOptionTypes.CUSTOM_AMOUNT) {
                    return isNaN(this.amount) || this.amount <= 0 || this.amount > MAX_AMOUNT_ALLOWED_BY_ACI_FOR_CASH_OUT_IN_DOLLARS
                    // verify bank info when customer is adding a new payment source
                } else if (this.selectedSavedPaymentSource === PaymentSources.ADD_PAYMENT) {
                    return (
                        this.routingNumber.length !== BANK_ROUTING_NUMBER_LENGTH ||
                        this.bankAccountNumber.length < BANK_ACCOUNT_NUMBER_MIN_LENGTH ||
                        this.bankAccountNumber.length > BANK_ACCOUNT_NUMBER_MAX_LENGTH ||
                        this.bankAccountNumber !== this.confirmBankAccountNumber
                    )
                } else {
                    return false
                }
            },
            isConfirmPaymentDisabled: function () {
                if (this.amount < MIN_NEED_TO_CONFIRM_PAYMENT_AMOUNT) {
                    return false
                }
                return parseFloat(this.confirmPaymentAmount) !== this.amount
            },
            nextAutoPayAmountAndDate() {
                return `${this.toFormattedUSD(this.nextAutoPayAmount)} on ${generic.utcToTimeZone(this.paymentDueDate, NYC_TIMEZONE, 'MMM D')}`
            },
        },
        methods: {
            ...mapActions(['updateAccountOverview', 'addPaymentSource', 'fetchPaymentSources', 'refreshAutoPayDetails', 'autoPayOptIn', 'autoPayOptOut']),
            prioritizePaymentSelection: function () {
                // Prioritize the default selectedPaymentOption
                if (this.minimumDue) {
                    this.selectedPaymentOption = PaymentOptionTypes.MINIMUM_DUE
                } else if (this.statementRemainingBalance) {
                    this.selectedPaymentOption = PaymentOptionTypes.STATEMENT_BALANCE
                } else if (this.currentBalance) {
                    this.selectedPaymentOption = PaymentOptionTypes.CURRENT_BALANCE
                } else {
                    this.selectedPaymentOption = PaymentOptionTypes.CUSTOM_AMOUNT
                    this.autoPayCustomAmount = this.autoPaySetting.autoPayDetails ? `${this.autoPaySetting.autoPayDetails.customAmount}` : null
                }
            },
            updateUiWithAutoPaySetting: function () {
                if (!this.autoPaySetting.isEnabled) {
                    this.selectedPaymentOption = PaymentOptionTypes.MINIMUM_DUE
                    return
                }
                this.selectedPaymentOption = this.autoPaySetting.autoPayDetails.autoPayCategory
                this.customPaymentAmount = this.autoPaySetting.autoPayDetails.customAmount
            },
            switchTabs: function () {
                if (this.isAutoPayTabActive) {
                    this.updateUiWithAutoPaySetting()
                    this.$logEvent('view_setup_autopay')
                } else {
                    this.prioritizePaymentSelection()
                    this.$logEvent('view_one_time_payment')
                }
            },
            saveAutoPay: async function () {
                this.$refs.paymentOptionsForm.clearErrorMessage()
                const selectedPayment = this.paymentSources[this.selectedSavedPaymentSource]
                logger.info(`Save and Opt In to Autopay with payment source: ${JSON.stringify(selectedPayment)}`)

                try {
                    logger.info(`Save autopay with: ${selectedPayment.accountType}, ${selectedPayment.token}, ${this.selectedPaymentOption}`)
                    const payload = {
                        autoPayCategory: this.selectedPaymentOption,
                        aciFundingTokenId: selectedPayment.token,
                        customAmount: this.customPaymentAmount,
                    }
                    await this.autoPayOptIn(payload)
                    if (this.error) {
                        this.$refs.paymentOptionsForm.applyGeneralError(this.error)
                        return false
                    }
                    return true
                } catch (e) {
                    ApiErrorHandler(e)
                    logger.error(`Error with avenAutoPayOptIn: ${e}`)
                    this.$refs.paymentOptionsForm.applyGeneralError('Sorry, something went wrong. Our team was notified and are quickly trying to resolve the issue.')
                    return false
                }
            },
            makePayment: async function () {
                this.$refs.paymentOptionsForm.clearErrorMessage()
                const selectedPayment = this.paymentSources[this.selectedSavedPaymentSource]
                logger.info(`Paying with selected payment source: ${JSON.stringify(selectedPayment)}`)
                try {
                    this.paymentDate = generic.getCurrentTimeInUtc()
                    let amountGoingToPay = this.amount
                    const logMessage = `date: ${this.paymentDate}, aciFundingToken: ${selectedPayment.token}, accountType: ${selectedPayment.accountType}, , amount: ${amountGoingToPay}`
                    logger.info(`make one time payment, params: ${logMessage}`)

                    const response = await aciMakePayment({
                        fundingTokenId: selectedPayment.token,
                        fundingSourceSubType: selectedPayment.accountType,
                        paymentDate: this.paymentDate,
                        paymentAmount: amountGoingToPay,
                    })
                    const data = response.data
                    if (!data.success) {
                        logger.info('MakePayment failed with', data.error)
                        this.$refs.paymentOptionsForm.applyGeneralError(data.error)
                        return false
                    }
                    this.amountPaid = amountGoingToPay
                    logger.info(`Payment successful. Success message: ${JSON.stringify(data)}`)

                    // we can try updating the account overview to see the payment reflected
                    await this.updateAccountOverview()
                    return true
                } catch (e) {
                    ApiErrorHandler(e)
                    this.$refs.paymentOptionsForm.applyGeneralError(i18n.t('pages.MakePayment.GenericError'))
                }
            },
            isLargePayment: function () {
                return this.amount >= MIN_NEED_TO_CONFIRM_PAYMENT_AMOUNT
            },
            getPaymentSourcesAndAutoPayDetails: async function () {
                logger.info('Getting payment sources')
                this.$refs.paymentOptionsForm.clearErrorMessage()
                try {
                    await this.fetchPaymentSources()
                    await this.refreshAutoPayDetails()
                    if (this.$store.getters.error) {
                        this.$refs.paymentOptionsForm.applyGeneralError(this.$store.getters.error)
                        return
                    } else if (this.paymentSources.length > 0) {
                        this.selectedSavedPaymentSource = 0
                    } else {
                        // assume that the user has no payment sources, show the add payment source screen
                        this.selectedSavedPaymentSource = PaymentSources.ADD_PAYMENT
                    }
                } catch (e) {
                    this.$refs.paymentOptionsForm.applyGeneralError(this.$store.getters.error)
                }
            },
            createPaymentSource: async function () {
                logger.info('Adding bank account for user')
                this.$refs.paymentOptionsForm.clearErrorMessage()
                try {
                    await this.addPaymentSource({
                        bankAccountType: this.bankAccountType,
                        routingNumber: this.routingNumber,
                        bankAccountNumber: this.bankAccountNumber,
                        accountNickName: this.accountNickName(this.bankAccountType, this.bankAccountNumber),
                    })
                    if (this.$store.getters.error) {
                        this.$refs.paymentOptionsForm.applyGeneralError(this.$store.getters.error)
                        return false
                    }
                    this.selectedSavedPaymentSource = this.paymentSources.length - 1
                    return true
                } catch (error) {
                    ApiErrorHandler(error)
                    this.$refs.paymentOptionsForm.applyGeneralError(i18n.t('pages.MakePayment.AddPaymentSourceError'))
                    return false
                }
            },
            validateAndSubmit: async function () {
                this.$refs.paymentOptionsForm.clearErrorMessage()
                let succeeded
                // 1. validate payment form
                succeeded = await this.$refs.paymentOptionsForm.$refs.observer.validate()
                if (!succeeded) {
                    // the form observer will pop validation and we break early from this function
                    return
                }
                this.loading = true

                // create payment source if there is none
                if (this.savePaymentOption && !this.haveSelectedPaymentSource) {
                    // user has not selected a saved payment source
                    succeeded = await this.createPaymentSource()
                    if (!succeeded) {
                        this.currentScreen = this.Screens.makePaymentOrAutoPay
                        this.loading = false
                        return
                    }
                }

                // autoPay tab is active, save auto pay setting
                if (this.isAutoPayTabActive) {
                    this.$logEvent('click_submit_setup_autopay')
                    const success = await this.saveAutoPay()

                    if (success) {
                        this.currentScreen = Screens.autoPayEnabledConfirmation
                    } else {
                        this.currentScreen = Screens.makePayment
                    }
                    // else make one time payment
                } else if (this.currentScreen === Screens.confirmOneTimePayment) {
                    this.$logEvent('click_submit_payment')
                    const success = await this.makePayment()

                    if (success) {
                        this.currentScreen = Screens.oneTimePaymentComplete
                    } else {
                        this.currentScreen = Screens.makePayment
                    }
                } else {
                    this.currentScreen = Screens.confirmOneTimePayment
                }
                this.loading = false
            },
            onClose: function () {
                // do not try to html inline this
                // it will not work in webview
                if (this.currentScreen === Screens.confirmOneTimePayment) {
                    // on confirmation page. redirect back to make a payment.
                    this.currentScreen = Screens.makePayment
                } else {
                    this.returnToRoot()
                }
            },
            accountNickName: function (accountType, accountNumber) {
                return `${startCase(accountType.toLowerCase())} (${accountNumber.substr(-4)})`
            },
            formattedPaymentDateToday: function (date) {
                return generic.utcToTimeZone(date, NYC_TIMEZONE, DISPLAY_DATE_FORMAT)
            },
            onClickAutoPayOptOut: async function () {
                await this.autoPayOptOut()
                this.showDisableAutoPaySetting = false
            },
        },
    }
</script>
