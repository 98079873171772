<template>
    <div class="container">
        <label
            class="switch"
            :for="id"
        >
            <input
                type="checkbox"
                :id="id"
                :name="id"
                :value="value"
                :checked="isChecked"
                :disabled="disabled"
                @click="onUpdate"
                @keydown.enter="onUpdate"
            >
            <div class="slider round">
                <span class="on">{{ leftLabel }}</span>
                <span class="off">{{ rightLabel }}</span>
            </div>
        </label>
    </div>
</template>

<script>
    export default {
        name: 'SwitchSlider',
        props: {
            // `id` will also populate `name` and `value`
            id: {
                type: String,
                required: true,
            },
            leftLabel: {
                type: String,
                default: 'ON',
            },
            rightLabel: {
                type: String,
                default: 'Off',
            },
            disabled: {
                type: Boolean,
            },
            value: {
                type: Boolean,
            },
            // will default to "toggle_unknown"
            eventName: {
                type: String,
                default: 'unknown',
            },
            eventProps: {
                type: [Object],
            },
        },
        computed: {
            isChecked: function () {
                return this.value
            },
        },
        methods: {
            onUpdate: function (event) {
                this.$logEvent(`toggle_${this.eventName}`, this.eventProps)
                this.$emit('input', event.target.checked)
                this.$emit('click', event.target.checked)
            },
        },
    }
</script>

<style lang="scss" scoped>
    .switch {
        position: relative;
        display: inline-block;
        width: 100%;
        height: 36px;
        border: 2px solid $primary;
        border-radius: 8px;
    }

    .switch input {
        display: none;
    }

    .slider {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        //position: absolute;
        height: 100%;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
        transition: 0.4s;
    }

    .slider:before {
        position: absolute;
        content: '';
        height: 36px;
        width: 50.125%;
        left: 0;
        //bottom: 4px;
        background-color: $primary;
        //opacity: 0.5;
        transition: 0.4s;
    }

    input:checked + .slider {
        background-color: white;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px $primary;
    }

    input:checked + .slider:before {
        transform: translateX(100%);
    }

    .on {
        color: white;
        z-index: 9999;
    }

    .on,
    .off {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
    }

    input:checked + .slider .on {
        color: black;
        display: block;
    }

    input:checked + .slider .off {
        color: white;
        z-index: 9999;
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 8px;

        &:before {
            border-radius: 8px;
        }
    }
</style>
